:root {
  --blue: #005BAC;
  --cyan: #5692C9;
  --gray: #F6F6F7;
  --gray-1: #333333;
  --gray-2: #bdbdbd;
  --gray-3: #DCDCDF;
  --gray-4: #696969;
  --dark: #9E7762;
  --dark-1: #e6eee6;
  --black: #101010;
  --black-2: #121212;
  --red: #dc3545;
  --red-2: #df4c4c;
  --red-3: #e66060;
  --red-4: #BF6161;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --green-2: #61BF87;
  --teal: #20c997;
  --white: #fff;
  --gray-dark: #343a40;
  --primary: #007bff;
  --primary-2: #255AA6;
  --primary-3: #E2F1FE;
  --primary-4: #2d6cc4;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --background: #F1EFEE;
  --orange: #FFE6CC;
  --violet: #A66FC0;

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}