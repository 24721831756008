/* ------------ Sign in page --------------*/
.signin-form--wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.login-form {
	border: 1px solid #dcdcdf;
	border-radius: 16px;
	padding: 43px 39px 35px 43px;
	width: 380px;
	background: var(--gray);
	transform: translateY(-15px);
}

.title {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: var(--dark);
	letter-spacing: 0.15px;
}

.signin-form--title {
	margin-bottom: 43px;
	text-align: center;
}

.site-form-item-icon {
	margin-right: 4px;
	color: var(--dark);
}

.login-form-button {
	width: 100%;
	background: var(--dark);
	height: 40px;
	border-radius: 8px;
}

.login-form-button span {
	letter-spacing: 1.25px;
}

.signin-form--input .ant-input-affix-wrapper {
	height: 43px;
	border-radius: 8px;
}

.text-normal {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: var(--dark);
}

.signin-form--helper {
	margin-top: 35px;
	text-align: center;
}

/* ------------ 404 page --------------*/
.page-404 {
	color: var(--dark);
	background: var(--background);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&--button {
		height: unset !important;
		font-size: 16px !important;
		border-radius: 8px;
		padding: 6px 30px 9px;
		display: flex;
		margin-top: 12px;
		overflow: hidden;
	}

	&--button:hover {
		background: var(--dark) !important;
		color: var(--white) !important;
	}
}

/* ----------------- Symptom page -----------------*/
.add-symptom-container {
	display: flex;
	justify-content: center;
	height: calc(100vh - 122px);

	&__input {
		margin-top: 48px;
	}
}

/* ------------- Account Page ---------------*/
.account-page-container {
	min-height: calc(100vh - 132px);
	background-color: var(--white);
	display: flex;
	flex-flow: column;
	justify-content: space-between;

	&__form {
		height: 60%;
		padding: 48px 75px;

		&--image-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 48px;

			img,
			.ant-image-mask {
				border-radius: 12px;
				max-height: 96px;
			}

			&--btn-group {
				display: flex;
				margin-left: 24px;

				button {
					margin-right: 12px;
				}
			}

			.ant-form-item {
				margin-bottom: 0px;
				margin-left: 24px;
			}

			.ant-upload.ant-upload-select-picture-card {
				width: 96px;
				height: 96px;
			}

			.ant-upload img {
				width: 100%;
				height: 100%;
			}
		}

		&--input-container {
			display: flex;
			justify-content: center;
			margin-bottom: 40px;

			&-item:not(:last-child) {
				margin-right: 100px;
			}

			.ant-input-number {
				width: 100%;
			}

			.ant-picker {
				width: 100%;
			}
		}

		&--button-container {
			display: flex;
			justify-content: center;
		}
	}
}

/* ------------- FAQ Page ---------------*/
.faq-page-container {
	min-height: calc(100vh - 132px);
	background-color: var(--white);
	display: flex;
	justify-content: center;

	&__form {
		width: 400px;
		margin-top: 48px;

		&__button-container {
			display: flex;
			justify-content: center;
			margin-top: 64px;
		}

		&__input {
			width: 80%;
		}
	}
}

.dynamic-delete-button {
	position: relative;
	top: 4px;
	margin: 0 8px;
	color: #999;
	font-size: 24px;
	cursor: pointer;
	transition: all 0.3s;
}

.dynamic-delete-button:hover {
	color: #777;
}

.dynamic-delete-button[disabled] {
	cursor: not-allowed;
	opacity: 0.5;
}

/* ------------- Chat Page ---------------*/
.chat-container {
	display: flex;
	background-color: var(--white);
	padding: 12px;
	min-height: calc(100vh - 132px);
	width: 100%;

	&__list {
		height: calc(100vh - 156px);
		width: 240px;
		border: 1px solid var(--dark);
		margin-right: 12px;
		overflow-y: scroll;
		position: relative;

		&__header {
			width: 235px;
			padding: 12px;
			position: sticky;
			top: 0;
			background-color: var(--white);
			z-index: 99;

			&__title {
				font-weight: 700;
				font-size: 20px;
				line-height: 26px;
				color: var(--black-2);
				// margin-bottom: 12px;
			}

			&__search {
				display: flex;
				justify-content: space-between;
				align-items: center;

				input {
					border-radius: 4px;
					border: 1px solid var(--gray-3);
				}

				&__icon {
					margin-left: 4px;
					display: flex;
					align-items: center;

					svg {
						cursor: pointer;
					}
				}
			}
		}

		&__body {
			padding: 12px 0px;
		}
	}

	&__list::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}

	&__main {
		position: relative;
		border: 1px solid var(--dark);
		width: calc(100% - 240px);
		padding: 12px 12px 0px 12px;
		height: calc(100vh - 156px);

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 12px 0px;
			box-shadow: inset 0px -1px 0px var(--gray-3);

			svg:last-child {
				margin-left: 12px;
			}

			span svg {
				cursor: pointer;
			}

			&__left {
				display: flex;

				div:first-child {
					margin-right: 12px;
				}

				&__name-section div:first-child {
					font-weight: 700;
					font-size: 20px;
					line-height: 26px;
					color: var(--black-2);
				}

				&__name-section div:last-child {
					font-weight: 700;
					font-size: 20px;
					line-height: 26px;
					color: var(--dark);
				}
			}
		}

		&__body {
			height: calc(100vh - 292px);
			overflow-y: scroll;
			padding: 12px 0px;
			display: flex;
			flex-direction: column-reverse;
		}

		&__body::-webkit-scrollbar {
			width: 0px;
			background: transparent;
		}

		&__typing {
			position: absolute;
			bottom: 48px;
			font-size: 10px;
		}

		&__input {
			position: absolute;
			bottom: -40px;
			width: 100%;
			padding-top: 12px;
			background-color: var(--white);
			z-index: 100;

			.ant-input-affix-wrapper {
				border: 1px solid var(--dark);
			}

			.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
				border-color: var(--dark);
			}

			svg {
				cursor: pointer;
			}

			&__container {
				display: flex;
				justify-content: space-between;
				align-items: center;

				svg {
					margin-left: 8px;
				}
			}
		}

		&__background {
			display: flex;
			justify-content: center;
			align-items: center;
			height: calc(100vh - 156px);
		}
	}

	.narrow {
		width: calc(100% - 700px);
		transition: 0.5s;
	}

	.full {
		width: calc(100% - 240px);
		transition: 0.5s;
	}

	.show-recommend {
		display: flex !important;
		flex-direction: column;
		justify-content: space-between;
	}

	.recommend-container {
		display: none;
		width: 460px;
		margin-left: 12px;

		&__search {
			height: calc(100% - 330px);
			background-color: var(--background);
			padding: 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&__main {
				display: flex;
				padding-bottom: 12px;

				button {
					margin-left: 12px;
				}
			}

			&__result {
				max-height: 250px;
				// height: calc(100% - 44px);
				overflow-y: scroll;
				&__item {
					display: flex;
					align-items: center;
					justify-content: space-between;
					overflow: hidden;
					margin-bottom: 8px;

					&__text {
						padding: 4px;
						background: white;
						width: calc(80% - 12px);
						white-space: break-spaces;
						word-break: break-word;
						// max-height: 80px;
						// overflow-y: scroll;
					}

					// button {
					//   width: 20%;
					// }
				}
			}

			&__quick-answer {
				&__container {
					display: flex;
					flex-wrap: wrap;
				}
				height: 32px;
				margin: 4px;
				cursor: pointer;
				&__title {
					width: 100%;
					height: 32px;
					display: flex;
					padding: 0px 12px;
					background-color: #9e7762;
					border-radius: 4px;
					color: white;
					align-items: center;
					justify-content: center;
				}
			}
		}

		&__button-group {
			margin: 12px 0px;
			display: flex;

			button {
				margin-right: 12px;
			}
		}

		&__product-container {
			background-color: var(--background);
			display: flex;
			overflow-y: hidden;
		}
	}
}

.sidebar-chat-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
