@font-face {
  font-family: 'Assistant', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/assistant.woff') format('woff'),
    /* Modern Browsers */
}

body {
  font-family: 'Assistant', sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: 0.18px;
}

/* ------------- Header ---------------*/
.app-header {
  background-color: var(--dark);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.ant-layout-header {
  padding: 0 25px !important;
}

.ant-layout.ant-layout-has-sider {
  height: 100%;
  min-height: 100vh;
}

.header-modal .ant-modal {
  width: 180px !important;
  right: 15px;
  float: right;
  top: 60px;
  margin: unset !important;
  border-radius: 8px;
}

.header-modal .ant-modal-content {
  border-radius: 4px !important;
  background-color: var(--gray);
}

.header-modal .ant-modal-content .ant-modal-body {
  padding: 15px 20px !important;
}

.header-modal .ant-modal-content .ant-modal-body div {
  cursor: pointer;
}

.header-modal .ant-modal-content .ant-modal-body div svg {
  width: 1.1em !important;
  height: 1.1em !important;
  // color: var(--dark);
}

.header-modal .ant-modal-content .ant-modal-body div span:hover {
  color: var(--dark);
}

.header-modal .ant-modal-content .ant-modal-body div:hover {
  color: var(--dark) !important;
}

.header-subtitle:hover {
  opacity: 0.8;
}

.header-subtitle {
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

/* ------------- Sidebar ---------------*/
.app-sidebar-logo {
  margin: 0px auto -10px auto;
  border-radius: 100%;
  display: block;
  width: 60px;
  height: 60px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.sidebar-menu-item:hover {
  background-color: var(--gray);
  color: var(--dark);
}

.sidebar-menu-item svg {
  width: 1.1em;
  height: 1.1em;
}

.sidebar-menu-item img.ant-menu-item-icon {
  height: 18px;
  width: 18px;
}

/* ------------- Layout ---------------*/
.ant-layout-content {
  background-color: var(--white);
}

.layout-content-admin {
  padding: 35px 25px 15px 25px;
}

/* -----------  Loading component  ----------- */
.ant-spin-spinning {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.user-page-container {
  background-color: var(--white);
  width: 100%;
  position: relative;

  .anticon-edit {
    color: var(--dark);
  }

  .anticon-delete {
    color: var(--red);
  }
}

.flex-center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-with-loading {
  min-width: 90px;

  .ant-spin-spinning {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    background-color: transparent
  }

  .anticon-loading {
    font-size: 12px !important;
  }
}

/* ------------- Table ---------------*/
.ant-pagination {
  display: flex;
  justify-content: center !important;
  float: unset !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.sidebar-menu {
  border-right: none
}

.ant-layout-sider-children {
  border-right: 1px solid #f0f0f0;
}

/* ------------- Form ---------------*/
.header-user-page {
  padding: 12px;

  &__content {
    display: flex;
    justify-content: space-between;

    &--left-btns {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      align-items: baseline;
      gap: 24px;

      .ant-input {
        max-width: 388px;
      }

    }

    &--search-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    &--delete {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      min-width: 100px;
    }

    &--train-model {
      margin-left: 12px;
    }
  }
}

.layout-container {
  margin-top: 24px;
  padding: 24px;
  min-height: calc(100vh - 84px);
  background-color: var(--background);

  .ant-table {
    padding: 0px 12px 12px 12px;
  }

  .ant-table-pagination.ant-pagination {
    margin: 0px;
    padding: 16px 0px;
    background: var(--background);
  }
}

.table-action {
  display: flex;
  gap: 27px;

  .anticon {
    cursor: pointer;
  }
}

.delete-modal {
  &__content {
    padding-bottom: 20px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

/* ------------- User Avartar ---------------*/
.user-avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: relative;

  &__white {
    background-color: var(--white);
  }

  &__gray {
    background-color: var(--background);
  }

  svg {
    position: absolute;
    right: 0;
    bottom: -1px;
  }

  &__main-chat {
    width: 48px;
    height: 48px;
    background-color: var(--background);

    img {
      height: 24px;
      width: 24px;
    }
  }
}

/* ------------- User Item ---------------*/
.user-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

  &__selected {
    background-color: var(--orange);
  }

  &__left {
    display: flex;
  }

  &__avatar {
    margin-right: 8px;
  }

  &__main {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark);

    div:first-child {
      color: var(--black-2);
    }

    div:last-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.18px;
      color: var(--gray-4);
    }
  }

  &__time {
    div {
      display: flex;
      flex-direction: row-reverse;
    }

    div:first-child {
      font-size: 10px;
      line-height: 14px;
      text-align: right;
      letter-spacing: 0.18px;
      color: var(--gray-4);
      margin-bottom: 6px;
    }

    div:last-child p {
      width: 14px;
      height: 14px;
      background-color: var(--green-2);
      border-radius: 100%;
      color: var(--white);
      font-size: 9px;
      line-height: 14px;
      font-weight: 600;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
  }
}

/* ------------- Message ---------------*/
.chat-message-containter {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  color: var(--black-2);

  .chat-message {
    padding: 8px;
    border-radius: 4px;
    background-color: var(--background);
    max-width: 100%;
    line-break: anywhere;
    white-space: break-spaces;

    .ant-table {
      padding: 0;

      .ant-table-thead {
        .ant-table-cell {
          background-color: #9E7762;
          color: white;
        }
      }
    }



    &__copy {
      margin-left: 8px;
      cursor: pointer;
    }

    &__physical-question {
      display: flex;
      margin-bottom: 4px;

      div {
        background-color: #FFE6CC;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #9E7762;
        color: #9E7762;
      }

      div:not(:last-child) {
        margin-right: 8px;
      }
    }

    &__mental-result {
      max-width: 300px;
      white-space: initial;
      &__canvas {
        display: flex;
        justify-content: center;
      }
    }

    &__products {
      padding-top: 12px;
    }
  }

  &__right {
    flex-direction: row-reverse;

    .chat-message {
      background-color: var(--orange);
    }
  }
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;

  &__flag {
    display: flex;
    color: #BF6161;
    align-items: center;
  }

  &__button {
    width: 100%;
    text-align: center;
    color: #9E7762;
    background: #FFFFFF;
    border: 1px solid #9E7762;
    border-radius: 4px;
    margin-top: 12px;
  }

  &__title {
    color: #121212;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &__image {
    width: 150px;
    margin-bottom: 12px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  // &__description {
  //   color: #A66FC0;
  // }

  &__variant {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    div {
      padding: 8px 16px;
      text-align: center;
      background-color: #FFFFFF;
      font-size: 12px;
      line-height: 16px;
      color: #9E7762;
      border-radius: 4px;
    }
  }
}

/* ------------- Chat Message Product ---------------*/
.chat-message {
  &__products {
    width: 272px;
  }
  .product-card__image {
    width: 100%;
    margin-bottom: 12px;
  }
  .message__product-card {
    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #121212;
      margin-bottom: 12px;
    }
  }
  .message__product-card__variant {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    div {
      padding: 8px 16px;
      text-align: center;
      background-color: #FFFFFF;
      font-size: 12px;
      line-height: 16px;
      color: #9E7762;
      border-radius: 4px;
    }
  }
}